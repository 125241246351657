@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Light.otf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Regular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Medium.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-SemiBold.otf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Bold.otf') format('truetype');
  font-weight: 700;
}

:root{
  --larin-green: #0AA09F;
  --larin-black: #483A3A;
  --larin-white: #ffffff;
  --light-green: #00E874;
}

html{
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Fira Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-green{color:var(--larin-green)}

/* MARGINS */
.mb-0{margin-bottom: 0;}
.mt-0{margin-top: 0;}
.mt-20{margin-top: 20px;}
.mt-30{margin-top: 30px;}
.mb-30{margin-bottom: 30px;}
.mt-40{margin-top: 40px;}
.mt-60{margin-top: 60px;}
.mb-60{margin-bottom: 60px;}
.mt-80{margin-top: 80px;}
.mt-100{margin-top: 100px;}
.mt-200{margin-top: 200px;}

.text-18{font-size: 18px;}

p{
  color:var(--larin-black);
  font-size: 20px;
  font-family: 'Fira Sans';
  font-weight: 300;
}


.title-h2{
  font-size: 40px;
  font-family: 'Fira Sans';
  font-weight: bold;
  color:var(--larin-black);
  max-width: 760px;
}

.title-h2.title-center{
  margin: auto;
}

.title-h3{
  text-align: center;
  font-size: 30px;
  font-family: 'Fira Sans';
  font-weight: 700;
}

.title-h5{
  font-size: 18px;
  font-family: 'Fira Sans';
  font-weight: 500;
  color: #483A3A;
}

.highlight-title{
  color:var(--white);
  background-color: var(--larin-green);
  border-radius: 50px;
  padding: 7px 10px;
}

.field label{
	color: #B1A1A1;
	font-size: 14px;
}
.field.label-float {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}
/**
* Add a transition to the label and input.
* I'm not even sure that touch-action: manipulation works on
* inputs, but hey, it's new and cool and could remove the 
* pesky delay.
*/
.label-float label, .label-float input {
  transition: all 0.2s;
  touch-action: manipulation;
}

.label-float input {
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  height:32px;
  z-index: 9;
  background: transparent;
}

.label-float input:focus {
  outline: 0;
  border-bottom: 1px solid #666;
}

.label-float label {
  font-size:12px;
  color: #B1A1A1;
}
/**
* Translate down and scale the label up to cover the placeholder,
* when following an input (with placeholder-shown support).
* Also make sure the label is only on one row, at max 2/3rds of the
* field—to make sure it scales properly and doesn't wrap.
*/
.label-float input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 1.6rem) scale(1.15);
}
/**
* By default, the placeholder should be transparent. Also, it should 
* inherit the transition.
*/
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
/**
* Show the placeholder when the input is focused.
*/
.label-float input:focus::-webkit-input-placeholder {
  opacity: 1;
}
/**
* When the element is focused, remove the label transform.
* Also, do this when the placeholder is _not_ shown, i.e. when 
* there's something in the input at all.
*/
.label-float input:not(:placeholder-shown) + label,
.label-float input:focus + label {
  transform: translate(0, 0.3rem) scale(1);
  cursor: pointer;
}

/* Customize the label (the checkbox-container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  color:#746666;
  line-height: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  border-radius:4px;
  background-color: #fff;
  border: 1px solid #CBC0C0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #0AA09F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
	left: 14px;
    top: 9px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* BUTTONS */

.btn-primary{
	border: 0 !important;
	border-radius: 50px !important;
	background-color: #0AA09F !important;
	color: #fff !important;
	font-size: 14px !important;
    padding: 7px 20px !important;
	font-weight: bold;
}
.btn-secondary{
	border: 2px solid #0AA09F !important;
	border-radius: 50px !important;
	background-color: transparent !important;
	color: #0AA09F !important;
	font-size: 14px !important;
    padding: 7px 20px !important;
	font-weight: bold;
}
.btn-link{
	border:0;
	background: transparent;
}

.btn1{
  border:solid 2px var(--larin-green);
  border-radius: 50px;
  padding: 15px 30px;
  background-color: var(--larin-green);
  color: var(--larin-white);
  font-size: 14px;
  font-weight: 700;
}


.btn2{
  border:solid 2px var(--larin-green);
  border-radius: 40px;
  padding: 10px 20px;
  color: var(--larin-green);
  font-size: 14px;
  font-weight: 700;
}

.subscribe{
  padding:40px;
  padding-bottom: 100px;
  background-color: var(--larin-green);
  position: relative;
}

.icon-left{
  position: absolute;
  bottom:150px;
  transform: rotate(-20deg);
  
}

.icon-right{
  position: absolute;
  top:150px;
  right: 0;
  transform: rotate(20deg);
}

.box-white{
  background-color: var(--larin-white);
  border-radius: 10px;
  padding: 20px 30px !important;
}
.subscribe .middle-col.box-white{
  margin: 0 -20px;
  margin-top: -30px;
  margin-bottom: -20px;
  border: 2px solid #0AA09F;
  box-shadow: 0px 6px 20px #0000004d;
  z-index: 99;
}
.subscribe .box-white .pop-option{
  position:absolute;
  bottom:-15px;
  right:-1px;
  background: #00E8E8;
  color: #483A3A;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 20px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px; 
}
.subscribe .box-white{
  padding-bottom: 170px !important;
}

.subscribe-inline{
  display: flex;
}

.subscribe-inline img{
  align-self: flex-start;
  margin-right: 10px;
}

.subscribe-list{
  display: block;
}

.subscribe-list{
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.subscribe-list img{
  padding-right: 10px;
  display: block;
    float: left;
}

.subscribe-bottom{
  position: absolute;
  bottom: 20px;
  left:0;
  width: 100%;
  padding: 0 30px;
}

.subscribe-btn{
  border: 2px solid var(--larin-green);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  width: 250px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 1.1;
  padding: 5px 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  position:relative;
}
.subscribe-btn a{
  height: calc(100% + 4px);
  position: absolute;
  top: -2px;
  right: -2px;
  background: #00E874;
  width: 150px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #483A3A;
  font-weight: bold;
}

.subscribe-btn a:hover{
  text-decoration: none;
  color: #483A3A;
}

.subscribe-btn a:active{
  background: #483A3A;
  color: #fff;
}
.subscribe-btn a:active:after{
  display:none;
}

.subscribe-btn span{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
}

.notes{
  font-size: 14px;
}

.zindex-max{
  transform: translateZ(300px);
}

/* HEADER */
#navbar-top
{
  padding-top:30px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  background-color: transparent;
  z-index: 99999;
  width: 100%;
  display: inline-block;
}
#navbar-top a{
  font-family: 'Fira Sans' !important;
}

.logo{
  float: left;
}

.header-navbar {
  align-items: center;
  display: flex;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 15px;
}

.header-navbar-item{
  margin-left:30px;
}

.header-navbar-item a {
  color: var(--larin-white);
  font-weight: 700;
  text-decoration: none;
}

.header-navbar-item a:hover {
  color: var(--larin-green);
  font-weight: 700;
  text-decoration: none;
}

.header-navbar-item a:active{
  color: #483A3A;
}

.header-navbar-item2 a{
  color: var(--larin-white);
  font-weight: 700;
  text-decoration: none;
  border: 2px solid var(--larin-white);
  border-radius: 40px;
  padding:10px 20px;
  background-color: transparent;
}

.header-navbar-item2 a:hover {
  background-color: #FFFFFF30;
  color:var(--larin-white);
  text-decoration: none;
}

.header-navbar-item2 a:active{
  color: #483A3A;
  border-color: #483A3A;
  background-color: transparent !important;
}

/* FOOTER */

.footer{
  padding-top: 0px;
  padding-bottom: 25px;
}

.footer-navbar {
  list-style: none;
  padding-left: 0px;
}

.footer-navbar-item{
  margin-bottom: 6px;
}


.footer-navbar-item a{
  color:var(--larin-green);
  font-weight: 700;
  font-size: 16px;
}

.footer-navbar-item a:hover{
  opacity: 0.7;
  color:var(--larin-green);
  text-decoration: none;
}
.footer-navbar-item a:active{
  color: #483A3A;
  opacity: 1;
}

.footer-navbar2 {
  align-items: center;
  display: flex;
  float: right;
  margin: 0;
  padding: 0;
}

.select{
  width: 100%;
  padding: 18px;
  border: 1px solid #CBC0C0;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
  color: #746666;
  margin-bottom: 20px;
  font-weight: 700;
  appearance: none;
  height: 60px;

}

.footer-navbar2 .footer-navbar-item2 a{
  color:var(--larin-green);
  font-weight: 700;
  font-size: 16px;
  margin-right: 30px;
}

.footer-navbar2 .footer-navbar-item2 a:hover{
  opacity: 0.7;
  text-decoration: none;
}

.footer-navbar2 .footer-navbar-item2 a:active{
  color: #483A3A;
  opacity: 1;
}

.footer-bottom{
  display: flex;
  align-items: center;
}

.footer-bottom img{
  width: 25px;
  margin-right: 10px;
}

.footer-bottom p{
  margin: 0px;
  font-size: 16px;
  font-weight: 300;
  color:#746666;
}

.navbar-footer h5{
  font-weight: 700;
  color: #746666;
  margin-bottom: 40px;
  font-size: 16px;
}

.hscroll-fake{
  height:600px;
  width:100%;
}

.hscroll-container{
  overflow-y: hidden;
  overflow-x: scroll;
  position: absolute;
  left: 0;
  width: 100vw;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hscroll-container::-webkit-scrollbar {
  display: none;
}

.hscroll-container .container{
  display: flex;
}
.hscroll-container .hscroll-1{
  width: 600px;
  margin-right: 40px;
}
.hscroll-container .hscroll-1 h2{
  margin-top: 8px;
  margin-bottom: 48px;
}
.hscroll-container .hscroll-1 p{
  width: 600px;
  font-size: 25px;
  line-height: 1.3;
  margin-right: 50px;
}
.hscroll-container .hscroll-2{
  margin-right: 40px;
}
.hscroll-container .hscroll-3{
  margin-right: 40px;
}
.hscroll-container .hscroll-3 p{
  font-size: 25px;
  line-height: 1.3;
  margin-top: 30px;
}
.hscroll-container .hscroll-4{
  margin-right: 40px;
}
.hscroll-container .hscroll-5 p{
  width: 600px;
  font-size: 25px;
  line-height: 1.3;
  margin-right: 50px;
}
.hscroll-container .hscroll-5 img{
  margin-top:10px;
}
.hscroll-arrow-left{
  position: absolute;
  left: 20px;
  transform: translateY(270px)rotate(180deg);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 0;
  background: #fff;
  color: #B1A1A1;
  box-shadow: -4px 3px 6px #00000033;
  display:none;
}
.hscroll-arrow-right{
  position: absolute;
  right: 20px;
  transform: translateY(270px);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 0;
  background: #fff;
  color: #B1A1A1;
  box-shadow: -4px 3px 6px #00000033;
}

.hscroll-arrow-left:hover,
.hscroll-arrow-right:hover,
.hscroll-arrow-left:focus,
.hscroll-arrow-right:focus{
  outline:0;
  background:#F0F0F0;
}
.hscroll-arrow-left:focus,
.hscroll-arrow-right:focus{
  background: #483A3A;
}

.register-container{
  display:flex;
  height: 100vh;
}
.register-left{
  width: calc(100vw - 400px);
}
.register-left img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.register-right{
  width: 400px;
  padding: 40px;
  overflow-y: overlay;
}

.register-email h2{
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
}
.birthdate-container{
	margin-top:20px;
	margin-bottom: 10px;
}
.birthdate{
	display:flex;
	position:relative;
}
.birthdate .birthdate-select{
	width:calc(100% / 3);
	margin: 5px;
	z-index: 999;
}
.birthdate .birthdate-select:first-child{
	margin-left:0;
}
.birthdate .birthdate-select:last-child{
	margin-right:0;
}
.warning-text{
	color: #746666;
	font-size: 12px;
}
.register-email .btn-primary{
	font-size:16px !important;
	font-weight:bold;
	padding: 15px 30px !important;
	width: 90%;
  margin-top: 40px;
}
.register-email .btn-primary:active{
  background-color: #483A3A !important;
}
.register-email .btn-primary:active:after{
  display:none;
}
.register-email .btn-primary:focus{
  box-shadow: none;
  outline:0;
}
.align-center{
	display:flex;
	justify-content:center;
}
.checkbox-container{
	line-height: 15px;
	margin-bottom:20px;
}
.register-error{
	color: red;
	font-size: 12px;
	margin-bottom:10px;
}
.register-error2{
	color: red;
	font-size: 12px;
	margin-top:15px;
	margin-bottom:0;
}

.register-phone-container{
  display:flex;
}
.register-phone-container .birthdate-select{
  padding-top: 9px;
  margin-right: 10px;
  width: calc(35% - 10px);
}
.register-phone-container .field{
  width: 65%;
}
.go-back-link{
  color: #0AA09F;
  font-size: 14px;
  font-weight:bold;
}
.go-back-link:hover{
  opacity: 0.7;
  color: #0AA09F;
  text-decoration: none;
}
.go-back-link:active{
  color: #483A3A;
  opacity: 1;
}
.text-link{
  color: #0AA09F;
}
.text-link:hover{
  opacity: 0.7;
  color: #0AA09F;
  text-decoration: none;
}
.text-link:active{
  opacity: 1;
  color: #483A3A;
}
.password-container{
  position:relative;
}
.btn-show-password{
  position: absolute;
  right: 0;
  bottom: 10px;
  color: #0AA09F;
  font-weight: bold;
  background: transparent;
  border: 0;
  z-index:9;
  font-size: 12px;
}
.btn-show-password:hover{
  opacity: 0.7;
}
.btn-show-password:active{
  opacity: 1;
  color: #483A3A;
}
.btn-show-password:focus{
  outline:0;
}
.carousel h1{
  margin-bottom: 0;
}
.carousel h1,
.carousel p,
.carousel a{
  font-family: 'Fira Sans' !important;
  line-height: 1.2;
}
.carousel a:not(.btn-white):hover{
  opacity: 0.7;
  text-decoration: none;
}
.carousel a:not(.btn-white):active{
  color: #483A3A;
  opacity: 1;
}
.carousel img{
  object-position: top;
}
.icon-text-section .img-container{
  height: 130px;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.carousel-inner .carousel-item {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  position: absolute;
}
.carousel-inner .carousel-item:first-of-type {
  position: relative;
}
.carousel-inner .active {
  opacity: 1;
}
.show-mobile{
  display:none !important;
}
.btn-white:hover{
  text-decoration:none;
  color: #fff;
  background: #0AA09F;
  position: relative;
}
.btn-white:hover:after{
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.3);
  border-radius:50px;
}
.btn-white:active{
  background: #483A3A;
  color: #fff;
}
.btn-white:active:after{
  display:none;
}
.btn-green:hover:after{
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.3);
  border-radius:50px;
}
.btn2:hover{
  text-decoration:none;
  color: #6EC3C2;
  border-color: #6EC3C2;
  position:relative
}
.btn2:active{
  border-color: #483A3A;
  color: #483A3A;
}
.btn1{
  border:0;
}
.btn1:hover{
  text-decoration:none;
  color: #fff;
  position:relative
}
.btn1:hover:after{
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.3);
  border-radius:50px;
}
.btn1:active{
  background: #483A3A;
  color: #fff;
}
.btn1:active:after{
  display:none;
}
.btn-primary:hover{
  text-decoration:none;
  color: #fff;
  position:relative
}
.btn-primary:hover:after{
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.3);
  border-radius:50px;
}
.select-wrapper{
  position:relative;
}
.select-wrapper img{
  position: absolute;
  right: 20px;
  top: 17px;
  rotate: 90deg;
  pointer-events: none;
}
@media only screen and (max-width: 600px) {
  .hide-mobile{
    display:none !important;;
  }
  .show-mobile{
    display:block !important;
  }
  p {
      font-size: 16px;
      text-align: left;
  }
  .header-navbar{
    display:none;
  }
  .carousel h1{
    font-size: 40px !important;
    line-height: 1.4;
  }
  .carousel .highlight-title{
    white-space: nowrap;
  }
  .carousel p{
    font-size: 40px !important;
    line-height: 1.4;
    text-align: center;
  }
  .title-h2{
    font-size: 30px;
    text-align: left;
  }
  .icon-text-section p{
    font-size:16px;
    text-align: center;
  }
  .icon-text-section .img-container{
    height: 100px;
    margin-bottom: 10px;
  }
  .icon-text-section img{
    width:100%;
    height:100%;
    padding: 10px 0;
  }
  .mt-200{
    margin-top: 100px;
  }
  p.text-18{
    font-size:16px;
  }
  .hscroll-container .hscroll-1{
    width:280px;
    min-width:280px;
  }
  .hscroll-container .hscroll-1 h2{
    margin-bottom: 21px;
  }
  .hscroll-container .hscroll-1 img{
    width:100%;
  }
  .hscroll-container .hscroll-2{
    width:280px;
    min-width:280px;
  }
  .hscroll-container .hscroll-2 img{
    width:100%;
  }
  .hscroll-container .hscroll-3{
    width:280px;
    min-width:280px;
  }
  .hscroll-container .hscroll-3 img{
    width:100%;
  }
  .hscroll-container .hscroll-4{
    width:280px;
    min-width:280px;
  }
  .hscroll-container .hscroll-4 img{
    width:100%;
  }
  .hscroll-container .hscroll-5{
    width:295px;
    min-width:295px;
    padding-right: 15px;
  }
  .hscroll-container .hscroll-5 p{
    width:100%;
  }
  .hscroll-container .hscroll-5 img{
    width:100%;
  }
  .hscroll-container p{
    font-size:17px !important;
  }
  .hscroll-fake{
    height: 480px !important;
  }
  .subscribe{
    padding: 20px;
  }
  .subscribe .middle-col.box-white{
    margin: 0;
  }
  .subscribe .box-white:not(:first-child){
    margin-top:20px;
  }
  .subscribe .box-white{
    padding-bottom: 20px !important;
  }
  .subscribe .title-h3{
    text-align: left !important;
    font-size: 25px;
  }
  .subscribe .title-h5{
    font-size:16px;
    margin-top:10px;
    margin-bottom:10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  .subscribe .box-white .pop-option{
    right: unset;
    left: 30px;
  }
  .open-accordion{
    position:relative;
  }
  .open-accordion p{
    font-size: 16px;
    color: #483A3A;
    margin:0;
    padding-right: 40px;
  }
  .open-accordion .btn-open-accordion{
    background: transparent;
    border:0;
    width: 30px;
    height: 30px;
    padding:0;
    position: absolute;
    right:0;
    top: 10px;
  }
  .open-accordion .btn-open-accordion img{
    transform: rotate(45deg);
  }
  .accordion-hide .btn-close-accordion{
    background: transparent;
    border:0;
    width: 30px;
    height: 30px;
    padding:0;
    position: absolute;
    right:30px;
    top: 20px;
  }
  .accordion-hide{
    padding-bottom:130px;
    padding-top: 20px;
  }
  .accordion-item{
    padding-bottom:5px;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  .accordion-body{
    margin-top:40px;
  }
  .accordion-button{
    border:0;
    background:transparent;
    padding:0;
  }
  .accordion-header h5{
    margin:0;
  }
  .footer{
    padding-bottom: 35px;
  }
  .footer-bottom{
    display:block;
  }
  .footer-bottom img{
    float:left;
  }
  .footer-bottom p{
    float:left;
  }
  .footer-navbar-mobile a{
    color: #0AA09F;
    font-weight: bold;
    font-size: 14x;
  }
  .footer-navbar-mobile span{
    color: #746666;
  }
  .footer-navbar-mobile{
    margin: 10px 0;
  }
  .register-right{
    width: 100vw;
  }
  .register-left{
    width: 0;
  }
  .icon-left{
    display:none;
  }
  .icon-right{
    display:none;
  }
  .logo{
    width: 100%;
    text-align: center;
  }
}