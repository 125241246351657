.carousel-indicators{
  z-index: 9999 !important;
  bottom: 5px !important;
  width: fit-content;
  margin: auto !important;
}

.carousel-indicators button{
   border:none;
   background-color: #CBC0C0 !important;
   width:10px !important;
   height: 10px !important;
   border-radius: 50px;
   margin-right: 5px;
   padding: 0px;
}

.carousel-indicators .active{
  border:solid 2px #CBC0C0 !important;
  background-color: #FFFFFF !important;
}

.carousel
{
  height: 660px;
}

.carousel img{
  height: 660px;
  object-fit: cover;
}


.carousel-caption{
  width: 100%;
  height: 100%;
  left:0 !important;
  top:0;
  background-color: #00000060;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-caption h1{
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0px;
}

.carousel-caption p{
  font-size: 60px;
  font-weight: 300;
  letter-spacing: 0px;
  color:#FFFFFF;
  margin-bottom: 50px;
}

.carousel-caption a{
  border-radius: 50px;
  background-color: #FFFFFF;
  color:#0AA09F;
  padding:15px 40px;
  
  font-weight: 700;
}

.carousel-caption a .link-arrow{
  height: 10px;
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
}
.carousel-caption a:hover .link-arrow{
  transform: translateX(10px);
}

.carousel-control-prev, .carousel-control-next{
  display: none !important;
}

.bg-white-transform{
  height: 90px;
  width: 110%;
  left: -5%;
  background-color: #FFFFFF;
  z-index: 999;
  position: absolute;
  top: 620px;
  transform: rotate(2deg);
}
.banner-caption{
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  z-index: 9999;
  transform: translateZ(10px);
}
.banner-caption h5{
  font-size: 14px;
  font-weight:bold;
  color:#483A3A;
  text-align: left;
  margin-bottom:0;
}
.banner-caption p{
  font-size: 14px !important;
  color:#483A3A !important;
  text-align: left;
}
.banner-caption p a{
  background:transparent;
  font-size: 14px;
  color:#0AA09F;
  padding:0;
  margin-left:5px;
}

@media only screen and (max-width: 600px) {
  .carousel img{
    object-position: 70% 40%;
  }
}